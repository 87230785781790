import React from 'react';
import './App.css';
import { MDBContainer, MDBRow, MDBCol,MDBRipple } from 'mdb-react-ui-kit';

import Header from './Header';
import ebay from './ebay_boxed.png'
import facebook from "./facebook.png";
import other from "./other_boxed.png";


function Main() {

  return (
      <div>
        <Header/>
          <MDBContainer>
                  <div className="d-flex align-items-center" style={{ height: "100px" }}>
                  <p className="fw-bolder fs-5">Lets get you a price - was your product listed on a market place?</p>
                  </div>
           </MDBContainer>

          <MDBContainer breakpoint="xxl">
              <MDBRow>
                  <MDBCol>
                      <MDBRipple
                          className='bg-image hover-zoom hover-overlay shadow-1-strong rounded'
                          rippleTag='div'
                          rippleColor='light'
                      >
                          <img
                              src={ebay}
                              height='150'
                              alt=''
                              loading='lazy'
                          />
                          <a href='/ebay'>
                              <div className='mask' style={{ backgroundColor: 'rgba(0, 183, 74, 0.78)' }}></div>
                          </a>
                      </MDBRipple>
                  </MDBCol>
                  <MDBCol>
                      <MDBRipple
                          className='bg-image hover-zoom hover-overlay shadow-1-strong rounded'
                          rippleTag='div'
                          rippleColor='light'

                      >
                          <img
                              src={other}
                              height='150'
                              alt=''
                              loading='lazy'
                          />
                          <a href='/nomarketplace'>
                              <div className='mask' style={{ backgroundColor: 'rgba(0, 183, 74, 0.78)' }}></div>
                          </a>
                      </MDBRipple>
                  </MDBCol>
              </MDBRow>
          </MDBContainer>


    </div>


  );
}

export default Main;
