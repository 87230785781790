import React, { Component } from 'react';
import {MDBCarousel, MDBCarouselItem} from "mdb-react-ui-kit";
import _ from "lodash";
export class ImageCarousel extends Component {

    render(props) {
        console.log("props***** : ", this.props.images.images)
        console.log("size : "+ this.props.images.images.length)
        return(
            <div>
            {this.props.images.images.length > 0 &&
                    <MDBCarousel showIndicators fade dark>
                        {_.times(this.props.images.images.length, (i) =>
                             <MDBCarouselItem className='w-100 d-block' key={i.toString()} itemId={i+1} src={this.props.images.images[i].url} alt='main image'></MDBCarouselItem>
                        )}
                    </MDBCarousel>
             }
            </div>
        )
    }

}