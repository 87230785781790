import React, { useRef,useState, useEffect } from 'react';
import Header from './Header';
import {useLocation, useNavigate} from 'react-router-dom';
import AddressSearch from 'react-loqate';
import What3wordsMap from './components/WhatThreeWords'
import './App.css';
import 'react-loqate/dist/react-loqate.cjs.development.css';
import axios from 'axios';

import {
    MDBContainer, MDBRow, MDBCol, MDBInput, MDBCardHeader,
    MDBCard,
    MDBCardImage,
    MDBCardBody,
    MDBCardText,
    MDBCheckbox,
    MDBBtn,
    MDBValidation,
    MDBValidationItem,
    MDBCardTitle,
    MDBInputGroup,  MDBCollapse, MDBIcon
} from 'mdb-react-ui-kit';
import firebaseApp from './auth/fire';
import {createUserWithEmailAndPassword, signInWithPhoneNumber, RecaptchaVerifier} from "firebase/auth";


function CollectionInfo() {

    const history = useNavigate();
    const location = useLocation();
    console.log("State + ", location);
    useEffect(() => {
    console.log("State + ", location.state);
    }, [location.state]);

   // console.log("State + ", location.state);
    const [referenceNumber, setReferenceNumber] = useState(generateReferenceNumber());

    const [collectionAddress, setCollectionAddress] = useState('');
    const [addressNotListed, setAddressNotListed] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [showAddress, setShowAddress] = useState(true);
    const [showWTWCard, setShowWTWCard] = useState(true);
    const [mobileCode, setMobileCode] = useState('');
    const [errorPhone, setErrorPhone] = useState('');
    const [wtwValue, setWtwValue] = useState("");
    const [showWTW, setShowWTW] = useState(false);
    const [user, setUser] = useState("");
    const wtwStateRef = useRef({})
    const [formValue, setFormValue] = useState({
        fname: '',
        lname: '',
        email: '',
        countryCode: '44',
        mobile: '',
        Line1: '',
        Line2: '',
        Line3: '',
        Line4: '',
        Line5: '',
        Province: '',
        PostalCode: '',
    });

    function generateReferenceNumber() {
        const timestamp = new Date().getTime();
        const random = Math.floor(Math.random() * 10000);
        const referenceNumber = `${timestamp}-${random}`;
        return referenceNumber;
    }

    const toggleShowWTWCard = () => setShowWTWCard(!showWTWCard);
    const toggleShowAddress = () => {
        if(addressNotListed === false) {
            setFormValue({ ...formValue, PostalCode: collectionAddress.PostalCode });
        }


        if(formValue.PostalCode)
        {
            //setCollectionAddress({PostalCode: formValue.PostalCode});
            setShowAddress(!showAddress);
            setShowWTWCard(!showWTWCard);
            setShowWTW(true);
        }
        else if (collectionAddress.PostalCode){
            setShowAddress(!showAddress);
            setShowWTWCard(!showWTWCard);
            setShowWTW(true);
        }
    };
    const toggleShow = () => {
        setShowContact(!showContact);
        setShowAddress(!showAddress);
    };






    const CustomInput = (props): JSX.Element => {
        return (
            <input
                placeholder={'start typing your address or postcode'}
                autoComplete="chrome-off"
                style={styles.addressSearchBox}
                disabled={addressNotListed}
                {...props}
            />
        );
    };



    const formatMobile = (countryCode,mobileNo) => {
        console.log("MOBILE IN : " + mobileNo);
        let mobile = "";
        let regex_num = mobileNo.replace(/^0+/,"");
        //remove spaces
        mobile = regex_num.replaceAll(' ', '');


        return "+"+countryCode+mobile;

    }

    const formatMobileOnFly = (mobileNo) => {
        console.log("MOBILE IN : " + mobileNo);
        let mobile = "";
        let regex_num = mobileNo.replace(/^0+/,"");
        //remove spaces
        mobile = regex_num.replaceAll(' ', '');

        console.log("MOBILE OUT : " + mobile);
        return mobile;

    }

    const handleClick = event => {

        const params = wtwStateRef.current
        const code = mobileCode;
        let addLine1 = "";
        let addLine2 = "";
        let addLine3 = "";
        let addLine4 = "";
        let addLine5 = "";
        let province = "";
        let postcode = ""
        let mobile = formatMobile(formValue.countryCode, formValue.mobile);

        if (formValue.Line1 === "") {
            addLine1 = collectionAddress.Line1;
            addLine2 = collectionAddress.Line2;
            addLine3 = collectionAddress.Line3;
            addLine4 = collectionAddress.Line4;
            addLine5 = collectionAddress.Line5;
            province = collectionAddress.Province;
            postcode = collectionAddress.PostalCode;
        }
        else {
            addLine1 = formValue.Line1;
            addLine2 = formValue.Line2;
            addLine3 = formValue.Line3;
            addLine4 = formValue.Line4;
            addLine5 = formValue.Line5;
            province = formValue.Province;
            postcode = formValue.PostalCode;
        }

        let url = "https://quotes.trkd.io/quote";
        const quote = {
            referenceNumber: referenceNumber,
            uid: location.state.customer.userId,
            collectionDetails: {
                fname: formValue.fname,
                lname: formValue.lname,
                email: formValue.email,
                mobile: mobile,
                line1: addLine1,
                line2: addLine2,
                line3: addLine3,
                line4: addLine4,
                line5: addLine5,
                province: province,
                postalCode: postcode,
                whatThreeWords: wtwValue,
            },
            customer: {
                fname: location.state.customer.fname,
                lname: location.state.customer.lname,
                email: location.state.customer.email,
                mobile: location.state.customer.mobile,
                line1: location.state.customer.line1,
                line2: location.state.customer.line2,
                line3: location.state.customer.line3,
                line4: location.state.customer.line4,
                line5: location.state.customer.line5,
                province: location.state.customer.province,
                postalCode: location.state.customer.postalCode,
                whatThreeWords: location.state.customer.whatThreeWords,
                userId: location.state.customer.userId,
                token: location.state.customer.token
            },
            ebayItem: {
                ebayMainImages: location.state.ebayItem.ebayMainImages,
                ebayTitle: location.state.ebayItem.ebayTitle,
                shortDescription: location.state.ebayItem.shortDescription,
                city: location.state.ebayItem.city,
                postalCode: location.state.ebayItem.postalCode,
                price: location.state.ebayItem.price,
                seller: location.state.ebayItem.seller,
                ebayImages: location.state.ebayItem.ebayImages
            }
        };

        axios.post(url, quote)
            .then(response => {
                console.log("***** Response: ", response.data)
                history('/complete', { state: {
                        referenceNumber: referenceNumber,
                        uid: location.state.customer.userId,
                        collectionDetails: {
                            fname: formValue.fname,
                            lname: formValue.lname,
                            email: formValue.email,
                            mobile: mobile,
                            line1: addLine1,
                            line2: addLine2,
                            line3: addLine3,
                            line4: addLine4,
                            line5: addLine5,
                            province: province,
                            postalCode: postcode,
                            whatThreeWords: wtwValue,
                        },
                        customer: {
                            fname: location.state.customer.fname,
                            lname: location.state.customer.lname,
                            email: location.state.customer.email,
                            mobile: location.state.customer.mobile,
                            line1: location.state.customer.line1,
                            line2: location.state.customer.line2,
                            line3: location.state.customer.line3,
                            line4: location.state.customer.line4,
                            line5: location.state.customer.line5,
                            province: location.state.customer.province,
                            postalCode: location.state.customer.postalCode,
                            whatThreeWords: location.state.customer.whatThreeWords,
                            userId: location.state.customer.userId,
                            token: location.state.customer.token
                        },
                        ebayItem: {
                            ebayMainImages: location.state.ebayItem.ebayMainImages,
                            ebayTitle: location.state.ebayItem.ebayTitle,
                            shortDescription: location.state.ebayItem.shortDescription,
                            city: location.state.ebayItem.city,
                            postalCode: location.state.ebayItem.postalCode,
                            price: location.state.ebayItem.price,
                            seller: location.state.ebayItem.seller,
                            ebayImages: location.state.ebayItem.ebayImages
                        }
                    }
                });

            })
            .catch(error => console.log("***** Error: ", error));




    };



    const onChange = (e: any) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };

    const onChangeMobile = (e: any) => {

        let mobile = formatMobileOnFly(e.target.value);
        setFormValue({ ...formValue, [e.target.name]: mobile });
    };

    const onChangeCountryCode = (e: any) => {
        let cc = e.target.value.replace(/^[a-z-A-Z]/,"");
        cc = cc.replace(/[^\w\s]/gi,"");
        setFormValue({ ...formValue, [e.target.name]: cc  });
    };


    const ShowWhatThree = () => {
        //console.log("CI: shipAdd.pc: "+ collectionAddress.PostalCode)
        let postCode = formValue.PostalCode;
        //let postCode = "IP21 4DS";
        if(postCode){
            return <What3wordsMap  inPostCode={postCode} stateRef={wtwStateRef} />;
        }
        else {
            return <p></p>;
        }

    };

    const handleOnChange = () => {
        setAddressNotListed(!addressNotListed);
        //console.log("Check box is " + addressNotListed);
    };

    const onChangeMobileCodeHandler = event => {
        setMobileCode(event.target.value);
    };


    return (
      <div>
          <Header/>
          <div id="recaptcha-container"></div>
          <br/>
          <MDBContainer>
              <MDBRow>
                  <MDBCol size='11' md='7'>
                      <MDBValidation>
                          <MDBValidationItem>

                                 <MDBCard>
                                     <MDBCardHeader>
                                         <b>STEP 1 - WHERE IS IT BEING COLLECTED FROM</b>
                                         {showContact &&
                                             <MDBIcon className='ms-1' icon='angle-double-down' onClick={toggleShow} size='lg' />
                                         }
                                     </MDBCardHeader>
                                     <MDBCollapse show={showContact}>
                                     <MDBCardBody>
                                         <MDBInput label='first name' name='fname' required onChange={onChange} id='formControlLg' value={formValue.fname} type='text' size='lg' />
                                         <div className='w-100'><br/></div>
                                         <MDBInput label="last name" name='lname' required onChange={onChange} id='formControlLg' value={formValue.lname} type='text' size='lg' />
                                         <div className='w-100'><br/></div>
                                         <MDBInput label='email' name='email' onChange={onChange} id='validationCustom03' type='text' size='lg' value={formValue.email} required />
                                         <div className='w-100'><br/></div>
                                         <h6>mobile number: i.e. + 44 123456596</h6>
                                         <MDBInputGroup textBefore={['+']} label='mobile'   type='text' size='lg' >
                                             <input id='validationCustom03' className='form-control' style={{ width: "5%" }} type='text' name="countryCode" onChange={onChangeCountryCode}  value={formValue.countryCode} required/>
                                             <input id='validationCustom04' className='form-control' style={{ width: "70%" }}type='text' name="mobile" onChange={onChangeMobile}  value={formValue.mobile} />
                                         </MDBInputGroup>
                                         <div className='w-100'><br/></div>
                                         <MDBBtn onClick={toggleShow}>Complete & Move To Step 2</MDBBtn>
                                     </MDBCardBody>
                                     </MDBCollapse>
                                 </MDBCard>

                              <div className='w-100'><br/></div>

                              <MDBCard>
                                  <MDBCardHeader>
                                      <b>STEP 2 -WHAT ADDRESS IS IT BEING COLLECTED FROM: </b>
                                      <MDBIcon className='ms-1' icon='angle-double-down' onClick={toggleShowAddress} size='lg' />
                                  </MDBCardHeader>
                                  <MDBCollapse show={showAddress}>
                                      <MDBCardBody>
                                          <AddressSearch
                                              locale="en-GB"
                                              apiKey="KG23-YM95-GC98-KW97"
                                              countries={['GB']}
                                              components={{
                                                  Input: CustomInput,
                                              }}
                                              onSelect={(address) => setCollectionAddress(address) }
                                              debounce={100}
                                          />
                                          <div className='w-100'><br/></div>
                                          <MDBCheckbox name='addressNotListed'
                                                       id='addressNotListed'
                                                       label='Address not listed'
                                                       checked={addressNotListed}
                                                       onChange={handleOnChange}/>
                                          <div className='w-100'><br/></div>

                                          {(collectionAddress.PostalCode) && (addressNotListed === false) &&
                                              <div>
                                                  <MDBInput label='Address' id='formControlLg' type='text' value={collectionAddress.Line1} name='Line1' onChange={onChange} size='lg' />
                                                  <div className='w-100'><br/></div>
                                                  <MDBInput label='Address' id='formControlLg' type='text' value={collectionAddress.Line2} name='Line2'  onChange={onChange} size='lg' />
                                                  <div className='w-100'><br/></div>
                                                  <MDBInput label='Address' id='formControlLg' type='text' value={collectionAddress.Line3} name='Line3'  onChange={onChange} size='lg' />

                                                  {collectionAddress.Line4 &&
                                                      <div>
                                                         <div className='w-100'><br/></div>
                                                         <MDBInput label='Address' id='formControlLg' type='text'  onChange={onChange} name='Line4'  value={collectionAddress.Line4} size='lg' />
                                                      </div>
                                                  }
                                                  {collectionAddress.Line5 &&
                                                      <div>
                                                          <div className='w-100'><br/></div>
                                                          <MDBInput label='Address' id='formControlLg' type='text' onChange={onChange} name='Line5'  value={collectionAddress.Line5} size='lg' />
                                                      </div>
                                                  }
                                                   <div className='w-100'><br/></div>
                                                  <MDBInput label='Address' id='formControlLg' type='text' value={collectionAddress.Province} name='Province' onChange={onChange}  size='lg' />

                                                  <div className='w-100'><br/></div>
                                                  <MDBInput label='Postcode'
                                                            id='validationCustom05'
                                                            name='PostalCode'
                                                            type='text'
                                                            value={collectionAddress.PostalCode}
                                                            onChange={onChange}
                                                            size='lg'
                                                            required
                                                  />

                                                  <div className='w-100'><br/></div>
                                                  <MDBBtn onClick={toggleShowAddress}>Complete Collection & Move to Step 3</MDBBtn>

                                              </div>
                                          }

                                              {(addressNotListed===true) &&
                                                  <div>
                                                      <MDBInput label='Address' id='formControlLg' type='text' value={formValue.Line1} name='Line1' onChange={onChange} size='lg' />
                                                      <div className='w-100'><br/></div>
                                                      <MDBInput label='Address' id='formControlLg' type='text' value={formValue.Line2} name='Line2' onChange={onChange} size='lg' />
                                                      <div className='w-100'><br/></div>
                                                      <MDBInput label='Address' id='formControlLg' type='text' value={formValue.Line3} name='Line3' onChange={onChange} size='lg' />

                                                      {collectionAddress.Line4 &&
                                                          <div>
                                                              <div className='w-100'><br/></div>
                                                              <MDBInput label='Address' id='formControlLg' type='text' value={formValue.Line4} name='Line4' onChange={onChange} size='lg' />
                                                          </div>
                                                      }
                                                      {collectionAddress.Line5 &&
                                                          <div>
                                                              <div className='w-100'><br/></div>
                                                              <MDBInput label='Address' id='formControlLg' type='text' value={formValue.Line5}  name='Line5' onChange={onChange} size='lg' />
                                                          </div>
                                                      }
                                                      <div className='w-100'><br/></div>

                                                                <MDBInput label='Province' id='formControlLg' type='text' value={formValue.Province} name='Province' onChange={onChange} size='lg' />
                                                                <div className='w-100'><br/></div>

                                                                <MDBInput label='Postcode'
                                                                          id='validationCustom05'
                                                                          required
                                                                          type='text'
                                                                          name='PostalCode'
                                                                          value={formValue.PostalCode}
                                                                          onChange={onChange}
                                                                          onFocus={() => setShowWTW(false) }
                                                                          size='lg' />
                                                              <div className='w-100'><br/></div>
                                                      <MDBBtn onClick={toggleShowAddress}>Complete Collection & Move to Step 3</MDBBtn>

                                                  </div>
                                              }
                                         </MDBCardBody>
                                  </MDBCollapse>
                                  </MDBCard>
                          </MDBValidationItem>
                      </MDBValidation>
                      <div className='w-100'><br/></div>
                      <MDBCard>
                          <MDBCardHeader><b>STEP 3 - Select a square for collection (if known):</b>
                              {showWTWCard &&
                                  <MDBIcon className='ms-1' icon='angle-double-down' onClick={toggleShowWTWCard} size='lg' />
                              }
                          </MDBCardHeader>
                          <MDBCollapse show={showWTWCard}>
                              <MDBCardBody>
                                  <ShowWhatThree />
                                  <MDBCardTitle><br/>{wtwValue}</MDBCardTitle>
                                  <div className='w-100'><br/></div>
                                  <MDBBtn onClick={handleClick}>Complete!</MDBBtn>
                              </MDBCardBody>
                          </MDBCollapse>
                      </MDBCard>
                      <div className='w-100'><br/></div>


                  </MDBCol>
                  <MDBCol size='11' md='5'>


                      <MDBCard>
                          <MDBCardHeader>
                              <b>BEING DELIVERED TO:</b>
                          </MDBCardHeader>

                          <MDBCardBody>
                              <MDBCardText>
                                    First Name: {location.state.customer.fname}<br/>
                                    Last Name: {location.state.customer.lname}<br/>
                                    Email: {location.state.customer.email}<br/>
                                    Phone: {location.state.customer.mobile}<br/>
                                    Address: {location.state.customer.line1}<span>, </span>
                                             {location.state.customer.line2}<span>, </span>
                                             {location.state.customer.line3}<span>, </span>
                                             {location.state.customer.province}<span>, </span>
                                             {location.state.customer.postalCode}
                                  <br/>
                              </MDBCardText>

                          </MDBCardBody>
                      </MDBCard>
                      <div className='w-100'><br/></div>
                      <MDBCard>
                          <MDBCardImage src={location.state.ebayItem.ebayMainImages}  position='top' alt='...' />
                          <MDBCardBody>
                              <MDBCardTitle> {location.state.ebayItem.ebayTitle}</MDBCardTitle>
                              <MDBCardText>
                                  Price: <b>{location.state.ebayItem.price}</b>
                              </MDBCardText>

                          </MDBCardBody>
                      </MDBCard>


                      <div className='w-100'><br/></div>

                  </MDBCol>
              </MDBRow>
                  <div className='w-100'></div>
          </MDBContainer>
      </div>
  );
}

const styles = {
    addressSearchBox: {
        width: "100%",
        height: "40px",
        borderRadius: "7px",
        position: "relative",
        backgroundColor: "#fff",
        transition: "0.4s al"
    }
};
export default CollectionInfo;
