import React, { useState } from 'react';
import {
    MDBNavbar,
    MDBContainer,
    MDBNavbarBrand,
    MDBBtn,
    MDBNavbarToggler, MDBIcon, MDBCollapse, MDBNavbarNav
} from 'mdb-react-ui-kit';
import logo from './logo.png'

function Header() {
    const [showBasic, setShowBasic] = useState(false);

    const myAccountLink = () => {
        const redirectUrl = 'https://account.trkd.io';
        window.location.href = redirectUrl;
    };

    return (
        <div>
            <header>
                <MDBNavbar expand='lg' light >
                    <MDBContainer fluid>
                        <MDBNavbarBrand href='https://www.trkd.io'>
                            <img
                                src={logo}
                                height='30'
                                alt=''
                                loading='lazy'
                            />
                        </MDBNavbarBrand>

                        <MDBNavbarToggler
                            aria-controls='navbarSupportedContent'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={() => setShowBasic(!showBasic)}
                        >
                            <MDBIcon icon='bars' fas />
                        </MDBNavbarToggler>

                        <MDBCollapse navbar show={showBasic}>
                            <MDBNavbarNav />

                            <MDBBtn color='dark' floating tag='a' onClick={myAccountLink}>
                                <MDBIcon fas icon='user' />
                            </MDBBtn>
                        </MDBCollapse>
                    </MDBContainer>
                </MDBNavbar>

            </header>
        </div>
    )
}

export default Header