import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import Main from './Main';
import Ebay from './Ebay';
import ClientInfo from "./ClientInfo";
import CreateVerifyUser from "./auth/CreateVerifyUser"
import CollectionInfo from "./CollectionInfo"
import CompleteTrip from "./CompleteTrip";
import NoMarketplace from "./NoMarketplace";
const App = () => (
    <Router>
        <Routes>
            <Route exact path="/" element={<Main />} />
            <Route path="/ebay" element={<Ebay />} />
            <Route path="/nomarketplace" element={<NoMarketplace />} />
            <Route path="/clientInfo" element={<ClientInfo />} />
            <Route path="/createUser" element={<CreateVerifyUser />} />
            <Route path="/collection" element={<CollectionInfo />} />
            <Route path="/complete" element={<CompleteTrip />} />

        </Routes>
    </Router>
);


export default App;