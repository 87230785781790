import React, { useRef,useState, useEffect } from 'react';
import Header from './Header';
import {useLocation, useNavigate} from 'react-router-dom';
import AddressSearch from 'react-loqate';
import What3wordsMap from './components/WhatThreeWords'
import './App.css';
import 'react-loqate/dist/react-loqate.cjs.development.css';


import {
    MDBContainer, MDBRow, MDBCol, MDBInput, MDBCardHeader,
    MDBCard,
    MDBCardImage,
    MDBCardBody,
    MDBCardText,
    MDBCheckbox,
    MDBBtn,
    MDBValidation,
    MDBValidationItem,
    MDBCardTitle,
    MDBInputGroup,  MDBCollapse, MDBIcon
} from 'mdb-react-ui-kit';
import firebaseApp from './auth/fire';
import {createUserWithEmailAndPassword, signInWithPhoneNumber, RecaptchaVerifier} from "firebase/auth";


function CompleteTrip() {

    const history = useNavigate();
    const location = useLocation();

    console.log("State + ", location);
    useEffect(() => {
        console.log("State + ", location.state);
    }, [location.state]);

    const goToAccount = () => {
        const accessToken = location.state.customer.token;

        const redirectUrl = 'https://account.trkd.io?page=' + accessToken;
        window.location.href = redirectUrl;
    };
    
    const handleClick = event => {
        console.log("State + ", location.state);
    };


    return (
      <div>
          <Header/>
          <div id="recaptcha-container"></div>
          <br/>
          <MDBContainer>
              <MDBRow>
                  <MDBCol size='7' md='5'>
                      <h3 className='mb-3'>YOUR REFERENCE: {location.state.referenceNumber}</h3>
                  </MDBCol>
                  <MDBCol size='5' md='5'>
                      <MDBBtn onClick={goToAccount}>See Prices Now</MDBBtn>
                  </MDBCol>
              </MDBRow>
              <MDBRow>
                  <MDBCol size='11' md='7'>
                      <MDBCard>
                          <MDBCardHeader>
                              <b>BEING COLLECTED FROM:</b>
                          </MDBCardHeader>
                          <MDBCardBody>
                              <MDBCardText>
                                  First Name: {location.state.collectionDetails.fname}<br/>
                                  Last Name: {location.state.collectionDetails.lname}<br/>
                                  Email: {location.state.collectionDetails.email}<br/>
                                  Phone: {location.state.collectionDetails.mobile}<br/>
                                  Address: {location.state.collectionDetails.line1}<span>, </span>
                                  {location.state.collectionDetails.line2}<span>, </span>
                                  {location.state.collectionDetails.line3}<span>, </span>
                                  {location.state.collectionDetails.province}<span>, </span>
                                  {location.state.collectionDetails.postalCode}
                                  <br/>
                              </MDBCardText>

                          </MDBCardBody>
                      </MDBCard>
                      <div className='w-100'><br/></div>


                  </MDBCol>
                  <MDBCol size='11' md='5'>


                      <MDBCard>
                          <MDBCardHeader>
                              <b>BEING DELIVERED TO:</b>
                          </MDBCardHeader>

                          <MDBCardBody>
                              <MDBCardText>
                                    First Name: {location.state.customer.fname}<br/>
                                    Last Name: {location.state.customer.lname}<br/>
                                    Email: {location.state.customer.email}<br/>
                                    Phone: {location.state.customer.mobile}<br/>
                                    Address: {location.state.customer.line1}<span>, </span>
                                             {location.state.customer.line2}<span>, </span>
                                             {location.state.customer.line3}<span>, </span>
                                             {location.state.customer.province}<span>, </span>
                                             {location.state.customer.postalCode}
                                  <br/>
                              </MDBCardText>

                          </MDBCardBody>
                      </MDBCard>
                      <div className='w-100'><br/></div>
                      <MDBCard>
                          <MDBCardImage src={location.state.ebayItem.ebayMainImages}  position='top' alt='...' />
                          <MDBCardBody>
                              <MDBCardTitle> {location.state.ebayItem.ebayTitle}</MDBCardTitle>
                              <MDBCardText>
                                  Price: <b>{location.state.ebayItem.price}</b>
                              </MDBCardText>

                          </MDBCardBody>
                      </MDBCard>


                      <div className='w-100'><br/></div>

                  </MDBCol>
              </MDBRow>
                  <div className='w-100'></div>
          </MDBContainer>
      </div>
  );
}


export default CompleteTrip;
