import {
    What3wordsAutosuggest,
    What3wordsMap
} from "@what3words/react-components";

import React, {useEffect, useState, useRef} from 'react'
import {useLocation} from 'react-router-dom';
import axios from "axios";
const API_KEY = "NCA5V7XF";
const MAP_API_KEY = "AIzaSyC2ju7nAdQVSEyqjfoKfSaMHLeYOrJhRuE";

const WhatThreeWords = ({inPostCode,stateRef}) => {
    const ref = useRef();
    const location = useLocation();
    const [googleLat, setGoogleLat] = useState('51.5145');
    const [googleLng, setGoogleLng] = useState('0.0803');
    const GetLatLng = (inPostCode) => {
       // console.log("WTW LOC PC: ", location.state)

        let postCode = inPostCode;
        let url = "https://maps.googleapis.com/maps/api/geocode/json?key="+MAP_API_KEY+"&components=postal_code:"+postCode
        if(postCode) {
            axios.get(url)
                .then(res => {
                    //console.log("GOOGLE DATA: ", res.data)
                    setGoogleLat(res.data.results[0].geometry.location.lat);
                    setGoogleLng(res.data.results[0].geometry.location.lng);
                })
                .catch(function (error) {
                    console.log("ERROR ", error.toJSON().status);
                });
        }

    };
    const [value, setValue] = useState("");

    useEffect(() => {GetLatLng(inPostCode)});

    useEffect(() => {
        stateRef.current = { value }
    }, [value])

    function updateWTHValue(val) {
        setValue(val);
    }

    useEffect(() => {
        ref.current.addEventListener("selected_square", (e) => {
           // console.log("moving in the blue box", e.x, e.y);
            //console.log("value",e.detail.words);
            updateWTHValue(e.detail.words)

        });
    }, []);


    return (
           <What3wordsMap
                api_key={API_KEY}
                map_api_key={MAP_API_KEY}
                zoom={18}
                selected_zoom={20}
                lng={googleLng}
                lat={googleLat}
                search_control_position={2}
                map_type_control={true}
                zoom_control={true}
                fullscreen_control={true}
                fullscreen_control_position={3}
                current_location_control_position={9}
                disable_default_ui={true}
                map_type_id="satellite"
                ref={ref}
           >
                <div slot="map" style={{ width: "100%", height: "83vh" }} />
                <div slot="search-control" style={{ margin: "10px 0 0 10px" }}>

                        <What3wordsAutosuggest>
                            <input
                                type="text"
                                placeholder="Find your address"
                                style={{ width: "300px" }}
                                autoComplete="off"
                            />
                        </What3wordsAutosuggest>
                    </div>

            </What3wordsMap>


    );
}
export default WhatThreeWords;