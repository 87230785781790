import React, { useEffect } from 'react';
import Uppy from '@uppy/core';
import { Dashboard, ProgressBar } from '@uppy/react';
import XHRUpload from '@uppy/xhr-upload';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/progress-bar/dist/style.css';
import AwsS3 from '@uppy/aws-s3';

function PreviewDropZone() {
    const uppy = new Uppy({ id: 'uppy1', autoProceed: true, debug: true });

    uppy.on('file-added', (file) => {
                console.log('File name:', file.name);

                if (uppy.getPlugin('customXHRUpload' + file.name)) {
                    uppy.removePlugin('customXHRUpload' + file.name);
                }
                uppy.use(AwsS3, {
                    id: 'customXHRUpload' + file.name,
                    companionUrl: 'http://companion.uppy.io',
                    getUploadParameters(file) {
                        return ({
                            method: 'PUT',
                            url: 'https://uploads.trkd.io/',
                            fields: {},
                            headers: {
                                'Content-Type': file.type,
                                'filename': file.name
                            },
                        });
                    },
                });
            })




    console.log('Rendering Component');

    return (
        <div>
            <Dashboard
                uppy={uppy}
                plugins={['XHRUpload']}
                metaFields={[
                    { id: 'name', name: 'Name', placeholder: 'File name' }
                ]}
            />
            <ProgressBar uppy={uppy} hideAfterFinish={false} />
            <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        </div>
    );
};

export default PreviewDropZone;
