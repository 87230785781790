import React, { useRef,useState } from 'react';
import Header from './Header';
import {useLocation} from 'react-router-dom';
import AddressSearch from 'react-loqate';
import What3wordsMap from './components/WhatThreeWords'
import './App.css';
import 'react-loqate/dist/react-loqate.cjs.development.css';
import { useNavigate } from "react-router-dom";
import {
    MDBContainer, MDBRow, MDBCol, MDBInput, MDBCardHeader,
    MDBCard,
    MDBCardImage,
    MDBCardBody,
    MDBCardText,
    MDBCheckbox,
    MDBBtn,
    MDBValidation,
    MDBValidationItem,
    MDBCardTitle,
    MDBInputGroup,  MDBCollapse, MDBIcon
} from 'mdb-react-ui-kit';
import firebaseApp from './auth/fire';
import {createUserWithEmailAndPassword, signInWithPhoneNumber, RecaptchaVerifier} from "firebase/auth";


function ClientInfo() {
    const history = useNavigate();
    const location = useLocation();
   // console.log("State + ", location.state);
    const [shipToAddress, setShipToAddress] = useState('hh');
    const [addressNotListed, setAddressNotListed] = useState(false);
    const [showContact, setShowContact] = useState(false);
    const [showAddress, setShowAddress] = useState(true);
    const [showWTWCard, setShowWTWCard] = useState(true);
    const [showVerifyMobile, setShowVerifyMobile] = useState(true);
    const [confirmationResult, setConfirmationResult]= useState({});
    const [mobileCode, setMobileCode] = useState('');
    const [errorPhone, setErrorPhone] = useState('');

    const toggleShowWTWCard = () => setShowWTWCard(!showWTWCard);
    const toggleShowAddress = () => {
        if(addressNotListed === false) {
            setFormValue({ ...formValue, PostalCode: shipToAddress.PostalCode });
        }


        if(formValue.PostalCode)
        {
            //setShipToAddress({PostalCode: formValue.PostalCode});
            setShowAddress(!showAddress);
            setShowWTWCard(!showWTWCard);
            setShowWTW(true);
        }
        else if (shipToAddress.PostalCode){
            setShowAddress(!showAddress);
            setShowWTWCard(!showWTWCard);
            setShowWTW(true);
        }
    };
    const toggleShow = () => {
        setShowContact(!showContact);
        setShowAddress(!showAddress);
    };

    const createPhone = (phoneNumber) => {

        console.log("createPhone");
        const auth = firebaseApp;
        const appVerifier = window.recaptchaVerifier;

        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                setConfirmationResult(confirmationResult);
                //console.log("*********** confirmationResult **************", confirmationResult);
                //appVerifier.clear();
            }).catch((error) => {
            // Error; SMS not sent
            console.log("*********** ERROR ************** : "+error.message);
        });


    }

    const trkdRecaptchaVerifier = (phoneNumber) => {
        console.log("trkdRecaptchaVerifier");

        const auth = firebaseApp;

        if (!window.recaptchaVerifier) {
            try{
                window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                    'size': 'invisible',
                    'callback': (response) => {
                        // reCAPTCHA solved, allow signInWithPhoneNumber.
                        //  onSignInSubmit();
                        console.log("RES ", response)
                        //createPhone(auth,phoneNumber,response)
                    },
                    'expired-callback': () => {
                        console.log("expired-callback ");
                    }
                }, auth);
            }
            catch(e) {
                console.log("We had a error",e);
            }

        }
    }


    const toggleShowVerifyMobile = () => {
        const params = wtwStateRef.current
        if(params.value) {
            let mobile = formatMobile(formValue.countryCode, formValue.mobile);
            trkdRecaptchaVerifier(mobile);
            createPhone(mobile);
            setWtwValue(params.value);
            setShowWTWCard(!showWTWCard);
            setShowVerifyMobile(!showVerifyMobile);
        }
        else {
            setWtwValue("No Square Selected");
            setShowVerifyMobile(!showVerifyMobile);
        }

    };
    const CustomInput = (props): JSX.Element => {
        return (
            <input
                placeholder={'start typing your address or postcode'}
                autoComplete="chrome-off"
                style={styles.addressSearchBox}
                disabled={addressNotListed}
                {...props}
            />
        );
    };

    const [wtwValue, setWtwValue] = useState("");
    const [showWTW, setShowWTW] = useState(false);
    const [user, setUser] = useState("");
    const wtwStateRef = useRef({})

    const formatMobile = (countryCode,mobileNo) => {
        console.log("MOBILE IN : " + mobileNo);
        let mobile = "";
        let regex_num = mobileNo.replace(/^0+/,"");
        //remove spaces
        mobile = regex_num.replaceAll(' ', '');


        return "+"+countryCode+mobile;

    }

    const formatMobileOnFly = (mobileNo) => {
        console.log("MOBILE IN : " + mobileNo);
        let mobile = "";
        let regex_num = mobileNo.replace(/^0+/,"");
        //remove spaces
        mobile = regex_num.replaceAll(' ', '');

        console.log("MOBILE OUT : " + mobile);
        return mobile;

    }

    const handleClick = event => {
        //console.log("Ship To Address is: ",shipToAddress )
        const params = wtwStateRef.current

        const code = mobileCode;
        // console.log("Code is " + code);


        console.log("PARAMS ********"+ wtwValue);
        if(wtwValue) {
            let addLine1 = "";
            let addLine2 = "";
            let addLine3 = "";
            let addLine4 = "";
            let addLine5 = "";
            let province = "";
            let postcode = ""

            let mobile = formatMobile(formValue.countryCode, formValue.mobile);


            if (formValue.Line1 === "") {
                addLine1 = shipToAddress.Line1;
                addLine2 = shipToAddress.Line2;
                addLine3 = shipToAddress.Line3;
                addLine4 = shipToAddress.Line4;
                addLine5 = shipToAddress.Line5;
                province = shipToAddress.Province;
                postcode = shipToAddress.PostalCode;
            } else {
                addLine1 = formValue.Line1;
                addLine2 = formValue.Line2;
                addLine3 = formValue.Line3;
                addLine4 = formValue.Line4;
                addLine5 = formValue.Line5;
                province = formValue.Province;
                postcode = formValue.PostalCode;
            }



            confirmationResult.confirm(code).then((result) => {
                // User signed in successfully.
                const userDetails = result.user;
                console.log("user ", userDetails);
                setUser(userDetails)

                history('/collection', { state: {
                        customer: {
                            fname: formValue.fname,
                            lname: formValue.lname,
                            email: formValue.email,
                            mobile: mobile,
                            line1: addLine1,
                            line2: addLine2,
                            line3: addLine3,
                            line4: addLine4,
                            line5: addLine5,
                            province: province,
                            postalCode: postcode,
                            whatThreeWords: wtwValue,
                            userId: result.user.uid,
                            token: result.user.accessToken
                        },
                        ebayItem: {
                            ebayMainImages: location.state.ebayMainImages,
                            ebayTitle: location.state.ebayTitle,
                            shortDescription: location.state.shortDescription,
                            city: location.state.city,
                            postalCode: location.state.postalCode,
                            price: location.state.price,
                            seller: location.state.seller,
                            ebayImages: location.state.ebayImages
                        }
                }
                });
               /* history('/collection', { state: {
                        client: {
                                fname: formValue.fname,
                                lname: formValue.lname,
                                email: formValue.email,
                                mobile: mobile,
                                line1: addLine1,
                                line2: addLine2,
                                line3: addLine3,
                                line4: addLine4,
                                line5: addLine5,
                                province: province,
                                postalCode: postcode,
                                whatThreeWords: params.value,
                                user: userDetails
                            },
                        ebayItem: {
                            ebayMainImages: location.state.ebayMainImages,
                            ebayTitle: location.state.ebayTitle,
                            shortDescription: location.state.shortDescription,
                            city: location.state.city,
                            postalCode: location.state.postalCode,
                            price: location.state.price,
                            seller: location.state.seller,
                            ebayImages: location.state.ebayImages
                        }
                    }
                });*/
                // ...
            }).catch((error) => {
                setErrorPhone("We had a error");
            });

            //console.log("formValue.mobile: " + formValue.mobile)



        }
        else {
            setWtwValue("No Square Selected");
        }

    };

    const [formValue, setFormValue] = useState({
        fname: '',
        lname: '',
        email: '',
        countryCode: '44',
        mobile: '',
        Line1: '',
        Line2: '',
        Line3: '',
        Line4: '',
        Line5: '',
        Province: '',
        PostalCode: '',
    });

    const onChange = (e: any) => {
        setFormValue({ ...formValue, [e.target.name]: e.target.value });
    };

    const onChangeMobile = (e: any) => {

        let mobile = formatMobileOnFly(e.target.value);
        setFormValue({ ...formValue, [e.target.name]: mobile });
    };

    const onChangeCountryCode = (e: any) => {
        let cc = e.target.value.replace(/^[a-z-A-Z]/,"");
        cc = cc.replace(/[^\w\s]/gi,"");
        setFormValue({ ...formValue, [e.target.name]: cc  });
    };


    const ShowWhatThree = () => {
        //console.log("CI: shipAdd.pc: "+ shipToAddress.PostalCode)
        let postCode = formValue.PostalCode;
        //let postCode = "IP21 4DS";
        if(postCode){
            return <What3wordsMap  inPostCode={postCode} stateRef={wtwStateRef} />;
        }
        else {
            return <p></p>;
        }

    };

    const handleOnChange = () => {
        setAddressNotListed(!addressNotListed);
        //console.log("Check box is " + addressNotListed);
    };

    const onChangeMobileCodeHandler = event => {
        setMobileCode(event.target.value);
    };


    return (
      <div>
          <Header/>
          <div id="recaptcha-container"></div>
          <br/>
          <MDBContainer>
              <MDBRow>
                  <MDBCol size='11' md='7'>
                      <MDBValidation>
                          <MDBValidationItem>

                                 <MDBCard>
                                     <MDBCardHeader>
                                         <b>STEP 1 - Your Contact Details</b>
                                         {showContact &&
                                             <MDBIcon className='ms-1' icon='angle-double-down' onClick={toggleShow} size='lg' />
                                         }
                                     </MDBCardHeader>
                                     <MDBCollapse show={showContact}>
                                     <MDBCardBody>
                                         <MDBInput label='first name' name='fname' required onChange={onChange} id='formControlLg' value={formValue.fname} type='text' size='lg' />
                                         <div className='w-100'><br/></div>
                                         <MDBInput label="last name" name='lname' required onChange={onChange} id='formControlLg' value={formValue.lname} type='text' size='lg' />
                                         <div className='w-100'><br/></div>
                                         <MDBInput label='email' name='email' onChange={onChange} id='validationCustom03' type='text' size='lg' value={formValue.email} required />
                                         <div className='w-100'><br/></div>
                                         <h6>mobile number: i.e. + 44 123456596</h6>
                                         <MDBInputGroup textBefore={['+']} label='mobile'   type='text' size='lg' >
                                             <input id='validationCustom03' className='form-control' style={{ width: "5%" }} type='text' name="countryCode" onChange={onChangeCountryCode}  value={formValue.countryCode} required/>
                                             <input id='validationCustom04' className='form-control' style={{ width: "70%" }}type='text' name="mobile" onChange={onChangeMobile}  value={formValue.mobile} />
                                         </MDBInputGroup>
                                         <div className='w-100'><br/></div>
                                         <MDBBtn onClick={toggleShow}>Complete & Move To Step 2</MDBBtn>
                                     </MDBCardBody>
                                     </MDBCollapse>
                                 </MDBCard>

                              <div className='w-100'><br/></div>

                              <MDBCard>
                                  <MDBCardHeader>
                                      <b>STEP 2 -Where is it being delivered: </b>
                                      <MDBIcon className='ms-1' icon='angle-double-down' onClick={toggleShowAddress} size='lg' />
                                  </MDBCardHeader>
                                  <MDBCollapse show={showAddress}>
                                      <MDBCardBody>
                                          <AddressSearch
                                              locale="en-GB"
                                              apiKey="KG23-YM95-GC98-KW97"
                                              countries={['GB']}
                                              components={{
                                                  Input: CustomInput,
                                              }}
                                              onSelect={(address) => setShipToAddress(address) }
                                              debounce={100}
                                          />
                                          <div className='w-100'><br/></div>
                                          <MDBCheckbox name='addressNotListed'
                                                       id='addressNotListed'
                                                       label='Address not listed'
                                                       checked={addressNotListed}
                                                       onChange={handleOnChange}/>
                                          <div className='w-100'><br/></div>

                                          {(shipToAddress.PostalCode) && (addressNotListed === false) &&
                                              <div>
                                                  <MDBInput label='Address' id='formControlLg' type='text' value={shipToAddress.Line1} name='Line1' onChange={onChange} size='lg' />
                                                  <div className='w-100'><br/></div>
                                                  <MDBInput label='Address' id='formControlLg' type='text' value={shipToAddress.Line2} name='Line2'  onChange={onChange} size='lg' />
                                                  <div className='w-100'><br/></div>
                                                  <MDBInput label='Address' id='formControlLg' type='text' value={shipToAddress.Line3} name='Line3'  onChange={onChange} size='lg' />

                                                  {shipToAddress.Line4 &&
                                                      <div>
                                                         <div className='w-100'><br/></div>
                                                         <MDBInput label='Address' id='formControlLg' type='text'  onChange={onChange} name='Line4'  value={shipToAddress.Line4} size='lg' />
                                                      </div>
                                                  }
                                                  {shipToAddress.Line5 &&
                                                      <div>
                                                          <div className='w-100'><br/></div>
                                                          <MDBInput label='Address' id='formControlLg' type='text' onChange={onChange} name='Line5'  value={shipToAddress.Line5} size='lg' />
                                                      </div>
                                                  }
                                                   <div className='w-100'><br/></div>
                                                  <MDBInput label='Address' id='formControlLg' type='text' value={shipToAddress.Province} name='Province' onChange={onChange}  size='lg' />

                                                  <div className='w-100'><br/></div>
                                                  <MDBInput label='Postcode'
                                                            id='validationCustom05'
                                                            name='PostalCode'
                                                            type='text'
                                                            value={shipToAddress.PostalCode}
                                                            onChange={onChange}
                                                            size='lg'
                                                            required
                                                  />

                                                  <div className='w-100'><br/></div>
                                                  <MDBBtn onClick={toggleShowAddress}>Complete Shipping & Move to Step 3</MDBBtn>

                                              </div>
                                          }

                                              {(addressNotListed===true) &&
                                                  <div>
                                                      <MDBInput label='Address' id='formControlLg' type='text' value={formValue.Line1} name='Line1' onChange={onChange} size='lg' />
                                                      <div className='w-100'><br/></div>
                                                      <MDBInput label='Address' id='formControlLg' type='text' value={formValue.Line2} name='Line2' onChange={onChange} size='lg' />
                                                      <div className='w-100'><br/></div>
                                                      <MDBInput label='Address' id='formControlLg' type='text' value={formValue.Line3} name='Line3' onChange={onChange} size='lg' />

                                                      {shipToAddress.Line4 &&
                                                          <div>
                                                              <div className='w-100'><br/></div>
                                                              <MDBInput label='Address' id='formControlLg' type='text' value={formValue.Line4} name='Line4' onChange={onChange} size='lg' />
                                                          </div>
                                                      }
                                                      {shipToAddress.Line5 &&
                                                          <div>
                                                              <div className='w-100'><br/></div>
                                                              <MDBInput label='Address' id='formControlLg' type='text' value={formValue.Line5}  name='Line5' onChange={onChange} size='lg' />
                                                          </div>
                                                      }
                                                      <div className='w-100'><br/></div>

                                                                <MDBInput label='Province' id='formControlLg' type='text' value={formValue.Province} name='Province' onChange={onChange} size='lg' />
                                                                <div className='w-100'><br/></div>

                                                                <MDBInput label='Postcode'
                                                                          id='validationCustom05'
                                                                          required
                                                                          type='text'
                                                                          name='PostalCode'
                                                                          value={formValue.PostalCode}
                                                                          onChange={onChange}
                                                                          onFocus={() => setShowWTW(false) }
                                                                          size='lg' />
                                                              <div className='w-100'><br/></div>
                                                      <MDBBtn onClick={toggleShowAddress}>Complete Shipping & Move to Step 3</MDBBtn>

                                                  </div>
                                              }
                                         </MDBCardBody>
                                  </MDBCollapse>
                                  </MDBCard>
                          </MDBValidationItem>
                      </MDBValidation>
                      <div className='w-100'><br/></div>
                      <MDBCard>
                          <MDBCardHeader><b>STEP 3 - Select a square for delivery:</b>
                              {showWTWCard &&
                                  <MDBIcon className='ms-1' icon='angle-double-down' onClick={toggleShowWTWCard} size='lg' />
                              }
                          </MDBCardHeader>
                          <MDBCollapse show={showWTWCard}>
                              <MDBCardBody>
                                  <ShowWhatThree />
                                  <MDBCardTitle><br/>{wtwValue}</MDBCardTitle>
                                  <div className='w-100'><br/></div>
                                  <MDBBtn onClick={toggleShowVerifyMobile}>Complete What Three Words</MDBBtn>
                              </MDBCardBody>
                          </MDBCollapse>
                      </MDBCard>
                      <div className='w-100'><br/></div>
                      <MDBCard>
                          <MDBCardHeader><b>Final Step - Verify your mobile</b>
                              {showWTWCard &&
                                  <MDBIcon className='ms-1' icon='angle-double-down' onClick={toggleShowVerifyMobile} size='lg' />
                              }
                          </MDBCardHeader>
                          <MDBCollapse show={showVerifyMobile}>
                              <MDBCardBody>
                                  <div className="d-flex justify-content-left">
                                      <div className="p-2" >We have sent a code to {formValue.countryCode+formValue.mobile}</div>
                                  <div className="p-2"><MDBBtn>Re-send OTP</MDBBtn></div>
                                  </div>
                                  <div className='w-100'><br/></div>
                                  <MDBInput label='mobileCode'
                                            id='mobileCode'
                                            type='text'
                                            name='mobileCode'
                                            value={mobileCode}
                                            onChange={onChangeMobileCodeHandler}
                                            size='lg'
                                  />
                                  <div className='w-100'><br/></div>
                                 <MDBBtn onClick={handleClick}>Verify Mobile</MDBBtn>
                                  <br/>
                                  {errorPhone}

                              </MDBCardBody>
                          </MDBCollapse>
                      </MDBCard>
                      <div className='w-100'><br/><br/></div>

                  </MDBCol>
                  <MDBCol size='11' md='5'>
                      <MDBCard>
                          <MDBCardImage src={location.state.ebayMainImages}  position='top' alt='...' />
                          <MDBCardBody>
                              <MDBCardTitle> {location.state.shortDescription}</MDBCardTitle>
                              <MDBCardText>

                                  Price: <b>{location.state.price}</b>
                              </MDBCardText>

                          </MDBCardBody>
                      </MDBCard>



                      <div className='w-100'><br/></div>

                  </MDBCol>
              </MDBRow>
                  <div className='w-100'></div>
          </MDBContainer>
      </div>
  );
}

const styles = {
    addressSearchBox: {
        width: "100%",
        height: "40px",
        borderRadius: "7px",
        position: "relative",
        backgroundColor: "#fff",
        transition: "0.4s al"
    }
};
export default ClientInfo;
