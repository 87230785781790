import {initializeApp} from "firebase/app";

import { getAuth } from "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyC2ju7nAdQVSEyqjfoKfSaMHLeYOrJhRuE",
    authDomain: "trkd-355913.firebaseapp.com",
    projectId: "trkd-355913",
    storageBucket: "trkd-355913.appspot.com",
    messagingSenderId: "863041149962",
    appId: "1:863041149962:web:4b2a47bf177c5331d313f3"
};



const iniConfig = initializeApp(firebaseConfig);
const firebaseApp = getAuth(iniConfig);
export default firebaseApp;
