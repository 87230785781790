import React, {useState, useEffect, useCallback} from 'react';
import Header from "../Header";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody, MDBCardFooter, MDBCardHeader,
    MDBCardText,
    MDBCol,
    MDBContainer,
    MDBInput,
    MDBRow,
    MDBTypography
} from "mdb-react-ui-kit";
import {useLocation} from "react-router-dom";

import firebaseApp from './fire'
import {createUserWithEmailAndPassword, signInWithPhoneNumber, RecaptchaVerifier} from "firebase/auth";

import "../App.css"

function CreateVerifyUser() {
    const location = useLocation();
    const [client, setClient] = useState(location.state.client);
    const [loadCapthaOnceCnt, setLoadCapthaOnceCnt] = useState(0);
    const [ebayItem, setEbayItem] = useState(location.state.ebayItem);
    //const app = initializeApp(config);
    let [mobileCode, setMobileCode] = useState('');
    let [confirmationResult, setConfirmationResult]= useState({});
// Initialize Firebase Authentication and get a reference to the service


    /*function createPhone() {
        console.log("createPhone");
        const phoneNumber = client.mobile;
        const auth = firebaseApp;
        const appVerifier = window.recaptchaVerifier;


        /*signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                setConfirmationResult(confirmationResult);
               //console.log("*********** confirmationResult **************", confirmationResult);
                appVerifier.clear();
            }).catch((error) => {
            // Error; SMS not sent
            console.log("*********** ERROR **************",error);
        });
    }*/

    const createPhone = useCallback(() => {

        console.log("createPhone");
        const phoneNumber = client.mobile;
        const auth = firebaseApp;
        const appVerifier = window.recaptchaVerifier;

        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                setConfirmationResult(confirmationResult);
               //console.log("*********** confirmationResult **************", confirmationResult);
                //appVerifier.clear();
            }).catch((error) => {
            // Error; SMS not sent
            console.log("*********** ERROR ************** : "+error.message);
        });


    }, []);

    const trkdRecaptchaVerifier = useCallback(() => {
        console.log("trkdRecaptchaVerifier");

        const phoneNumber = client.mobile;
        const auth = firebaseApp;

        if (!window.recaptchaVerifier) {
            try{
                window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                    'size': 'invisible',
                    'callback': (response) => {
                        // reCAPTCHA solved, allow signInWithPhoneNumber.
                        //  onSignInSubmit();
                        console.log("RES ", response)
                        //createPhone(auth,phoneNumber,response)
                    },
                    'expired-callback': () => {
                        console.log("expired-callback ");
                    }
                }, auth);
            }
            catch(e) {
                console.log("We had a error",e);
            }

        }
    }, [client.mobile]);




    useEffect(() => {
            console.log("**** useEffect: ");
            trkdRecaptchaVerifier();
            createPhone();


        /*createUserWithEmailAndPassword(auth, client.email, "pa55w@rd")
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log(user)
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorMessage)
            });*/


    },[loadCapthaOnceCnt,trkdRecaptchaVerifier, createPhone]);

    const reSendCode = event => {
        //window.recaptchaVerifier.clear();
        trkdRecaptchaVerifier();
        createPhone();
    }

    const submitCode = event => {
        const code = mobileCode;
       // console.log("Code is " + code);
        confirmationResult.confirm(code).then((result) => {
            // User signed in successfully.
            const user = result.user;
            console.log("user ", user);
            // ...
        }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            //console.log("error ", error);

        });
    }

    const onChangeHandler = event => {
        setMobileCode(event.target.value);
    };

        return (
            <div>

                <Header/>
                <div id="recaptcha-container"></div>
                <div className='w-100'><br/></div>

                <MDBContainer>

                    <MDBRow>
                        <MDBCol size='11' md='5'>
                                    <MDBCard alignment='center'>
                                        <MDBCardHeader>OTP Sent: <b>{client.mobile}</b> </MDBCardHeader>
                                        <MDBCardBody>
                                                <MDBInput label='mobileCode'
                                                          id='mobileCode'
                                                          type='text'
                                                          name='mobileCode'
                                                          value={mobileCode}
                                                          onChange={onChangeHandler}
                                                          size='lg'
                                                          />

                                            <div className="d-flex justify-content-center">
                                                <div className="p-2" ><MDBBtn  onClick={submitCode} >submit Code</MDBBtn></div>
                                                <div className="p-2"><MDBBtn  onClick={reSendCode} >Re-send OTP</MDBBtn></div>
                                            </div>
                                        </MDBCardBody>
                                        <MDBCardFooter className='text-muted'>
                                            <MDBTypography note noteColor='success'>
                                                <strong>One Time Passcode: </strong>
                                                is valid for 15 minutes
                                            </MDBTypography>
                                        </MDBCardFooter>
                                    </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
        );

    };
export default CreateVerifyUser;