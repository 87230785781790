import React, { useState } from 'react';
import './App.css';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardHeader,
    MDBTooltip,
    MDBIcon, MDBCollapse
} from 'mdb-react-ui-kit';
import axios from 'axios';
import Header from './Header';
import ebay from './ebay_boxed.png'
import ebay_item_ss from './ebay_item_ss.png'
import {ImageCarousel} from "./imageCarousel";
import parse from 'html-react-parser'
import { useNavigate } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
function Ebay() {

    const history = useNavigate();
    const [ebayId, setEbayId] = useState('');
    let [btnText, setBtnText] = useState('Get Product Information ');
    let [errorText, setErrorText] = useState('');
    let [ebayImages, setEbayImages] = useState([]);
    let [ebayMainImages, setEbayMainImages] = useState([]);
    let [ebayDesc, setEbayDesc] = useState('');
    let [ebayTitle, setEbayTitle] = useState('');
    let [shortDescription, setShortDescription] = useState('');
    let [city, setLocatedCity] = useState('');
    let [postalCode, setLocatedPostalCode] = useState('');
    let [price, setPrice] = useState('');
    let [seller, setSellerDetails] = useState({});

    const [showShow, setShowShow] = useState(true);

    const toggleShow = () => setShowShow(!showShow);


    const addPosts = async (ebayParsedID) => {

        let url = "https://mp.trkd.io/marketplaces/getEbayItem/"+ebayParsedID
        if(ebayParsedID) {
            axios.get(url)
                .then(res => {
                    setBtnText("Get Product Information");
                    //console.log(res.data);
                    //let imageHtml = <img src={res.data.image.imageUrl}/>;
                    setEbayTitle(res.data.title)
                    setEbayDesc(res.data.description);
                    setEbayMainImages(res.data.image.imageUrl);
                    setShortDescription(res.data.shortDescription);
                    setLocatedCity(res.data.itemLocation.city);
                    setLocatedPostalCode(res.data.itemLocation.postalCose);
                    setPrice(res.data.price.value);
                    setSellerDetails(res.data.seller);

                    let imageList = [];
                    imageList.push({type: "main", url: res.data.image.imageUrl});
                    for (let item of res.data.additionalImages) {
                        imageList.push({type: "additional", url: item.imageUrl});
                    }

                    setEbayImages(imageList);
                })
                .catch(function (error) {
                    setBtnText("Get Product Information");
                    setEbayImages([]);
                    //console.log("ERROR ", error.toJSON().status);
                    //console.log("ERROR ", error.toJSON().message);
                    setErrorText("TRKD can not find the item at this time, please double check the item id")
                });
        }
        else {
            setBtnText("Get Product Information");
            setErrorText("The item id is blank")
            setEbayImages([]);
        }

    };

    function removeHttp(str) {
        return str.replace(/(http(s)?:\/\/)/g, '');
    }

    function extractNumber(str) {
        const regex = /\/(\d+)\?/;
        const match = str.match(regex);
        return match ? match[1] : null;
    }

    function startsWithNumber(str) {
        return /^\d/.test(str);
    }

    function handleClick() {
        setErrorText('');
        setBtnText("Loading Please Wait....")
        //console.log("Ebay id "+ ebayId); // Output: "example"
        let extartedEbayID = "";
        if (startsWithNumber(ebayId)) {
            extartedEbayID = ebayId;
            setEbayId(extartedEbayID);
            console.log("IS Number  WORKING OUTPUT :" + extartedEbayID);
            addPosts(extartedEbayID).then(r => console.log("OK")).catch((err) => {
                console.log(err.message);
            });
        } else {
            let string = ebayId;
            const httpRemoveOutput = removeHttp(string);
            const extartedEbayID = extractNumber(httpRemoveOutput);
            console.log("Not a NumberTHE WORKING OUTPUT :" + extartedEbayID);
            setEbayId(extartedEbayID);
            addPosts(extartedEbayID).then(r => console.log("OK")).catch((err) => {
                console.log(err.message);
            });
        }

    }

    const onChangeHandler = event => {
        setEbayId(event.target.value);
    };
    const onClickHandler = event => {
        if(ebayId.length > 0) {
            setEbayId("");
            setEbayImages([]);
            setEbayDesc("");
            setEbayTitle("");
        }
    };

    const onClickClientDetailsHandler = event => {

        history('/clientInfo',{state: { ebayMainImages : ebayMainImages,
                                        ebayTitle: ebayTitle,
                                        shortDescription: shortDescription,
                                        city: city,
                                        postalCode: postalCode,
                                        price: price,
                                        seller: seller,
                                        ebayImages: ebayImages}});
    };

    const ShowImages = () => {
       // console.log("Child re-renders : ", ebayImages);
        if(ebayImages !== null){
            return <ImageCarousel images={{images:ebayImages}} />;
        }
        else {
           return <p></p>;
        }

    };


    return (
      <div>
          <Header/>
          <br/>
          <MDBContainer>
              <MDBRow>
                  <MDBCol md='4'>
                      <img
                          src={ebay}
                          height='60'
                          alt=''
                          loading='lazy'
                      />
                  </MDBCol>
              </MDBRow>
              <MDBRow>
                  <MDBCol md='10'>
                      <br/>
                  </MDBCol>
              </MDBRow>
              <MDBRow>
                  <MDBCol size='7' sm='4'>
                      <MDBInput label='Ebay Item ID or Ebay URL' id='ebayId' type='text' size='lg' onChange={onChangeHandler} onClick={onClickHandler} value={ebayId}/>

                  </MDBCol>
                  <MDBCol size='3' sm='2'>
                          <MDBBtn  onClick={handleClick} >{btnText}</MDBBtn>
                  </MDBCol>
                  <MDBCol size='12' sm='6'>
                      {ebayDesc &&
                          <MDBCard>
                              <MDBCardHeader>Is this your product?   <MDBBtn onClick={onClickClientDetailsHandler}>Yes this is it</MDBBtn></MDBCardHeader>

                              <MDBCardBody>
                                  <MDBCardTitle>{ebayTitle}</MDBCardTitle>
                                  <ShowImages/>
                                  <ErrorBoundary fallback={<p>No Description Found</p>}>
                                      {parse(ebayDesc)}
                                  </ErrorBoundary>
                                  <MDBCardText></MDBCardText>

                              </MDBCardBody>
                          </MDBCard>
                      }
                  </MDBCol>
                  <div className='w-100'></div>
              </MDBRow>
              <MDBRow>
                  <MDBCol md='10'>
                      <b>{errorText}</b> <br/>
                  </MDBCol>
              </MDBRow>
          </MDBContainer>


      </div>
  );
}

export default Ebay;
