import React, { useState, useEffect,useMemo } from 'react';
import {useDropzone} from 'react-dropzone';
import PreviewDropZone from './components/PreviewDropZone';
import Header from './Header';
import {
    MDBContainer,
    MDBCard,
    MDBCardHeader,
    MDBCardBody,
    MDBInput,
    MDBRow,
    MDBCol,
    MDBTextArea
} from 'mdb-react-ui-kit';

function NoMarketplace({location}) {
    const [formValues, setFormValues] = useState({
        ebayTitle: "",
        shortDescription: "",
        weight: "",
        price: "",
        height: "",
        width: "",
        length: "",
        ebayImages: null
    });

    useEffect(() => {
        if(location && location.state) {
            setFormValues({
                ebayTitle: location.state.ebayTitle,
                shortDescription: location.state.shortDescription,
                weight: location.state.weight,
                price: location.state.price,
                height: location.state.height,
                width: location.state.width,
                length: location.state.length,
                ebayImages: location.state.ebayImages
            });
        }
    }, [location]);

    const onChange = (e) => {
        if (e.target.name === 'ebayImages') {
            setFormValues({
                ...formValues,
                [e.target.name]: e.target.files[0]
            });
        } else {
            setFormValues({
                ...formValues,
                [e.target.name]: e.target.value
            });
        }
    }


    return (
        <div>
            <Header />
            <br />
            <MDBContainer>
                <MDBRow>
                    <MDBCol size='11' md='7'>
                        <MDBCard>
                            <MDBCardHeader><b>Item Details</b></MDBCardHeader>
                            <MDBCardBody>
                                <MDBInput label='Title' name='ebayTitle' required onChange={onChange} id='formControlLg' value={formValues.ebayTitle} type='text' size='lg' />
                                <div className='w-100'><br/></div>
                                <MDBTextArea label='Short Description' name='shortDescription' required onChange={onChange} id='formControlLg' value={formValues.shortDescription} type='text' size='lg' />
                                <div className='w-100'><br/></div>
                                <MDBInput label='Price' name='price' required onChange={onChange} id='formControlLg' value={formValues.price} type='text' size='lg' />
                                <div className='w-100'><br/></div>
                                <MDBInput label='Weight (KG)' name='weight' required onChange={onChange} id='formControlLg' value={formValues.price} type='text' size='lg' />
                                <div className='w-100'><br/></div>
                                <MDBRow>
                                    <MDBCol size='5' md='4'>
                                        <MDBInput label='Height' name='height' required onChange={onChange} id='formControlLg' value={formValues.price} type='text' size='lg' />
                                         <div className='w-100'><br/></div>
                                    </MDBCol>
                                    <MDBCol size='5' md='4'>
                                        <MDBInput label='Width' name='width' required onChange={onChange} id='formControlLg' value={formValues.price} type='text' size='lg' />
                                        <div className='w-100'><br/></div>
                                    </MDBCol>
                                    <MDBCol size='5' md='4'>
                                        <MDBInput label=' Length' name='length' required onChange={onChange} id='formControlLg' value={formValues.price} type='text' size='lg' />
                                        <div className='w-100'><br/></div>
                                    </MDBCol>
                                </MDBRow>
                                <PreviewDropZone />
                                

                        </MDBCardBody>
                    </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
    );
}

export default NoMarketplace
